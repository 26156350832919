import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  StyledTableCell,
  pageSize,
  TablePaginationStyle,
  paginationLabel,
  NoDataContainer,
  StyledSortLabel,
} from "../../../../Common/styles/table";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import {
  setScorePage,
  setSortColumn,
  setSortOrder,
} from "../../../../../Redux/reducers/myPatientsSlice";
import http from "../../../../../utils/http";
import { DateTime } from "luxon";
import { errorToastMessage } from "../../../../../utils/toast";

const ActionScores = () => {
  const dispatch = useAppDispatch();
  const page = useAppSelector((state) => state.myPatients.scorePage);
  const { sortOrder, sortColumn } = useAppSelector((state) => state.myPatients);

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const startDate = DateTime.now().minus({ months: 3 }).toUTC().toISO();
        const endDate = DateTime.now().toUTC().toISO();

        let url = `/action-score?page=${
          page + 1
        }&size=${pageSize}&userId=${id}&startDate=${startDate}&endDate=${endDate}`;

        if (sortOrder && sortColumn) {
          url += `&order=${sortOrder}&orderBy=${sortColumn}`;
        }

        // if (statinsFilter) {
        //   url += `&status=${statinsFilter}`;
        // }

        const res = await http.get(url);

        const formattedData = res?.data?.data?.rows?.map((item: any) => {
          const scoreDetails = item?.scoreDetails;
          return {
            id: item?.id,
            totalScore: Math.round(item?.score || 0),
            medication: Math.round(scoreDetails?.medication?.score || 0),
            food: Math.round(scoreDetails?.food?.score || 0),
            steps: Math.round(scoreDetails?.step?.score || 0),
            sleep: Math.round(scoreDetails?.sleep?.score || 0),
            learn: Math.round(scoreDetails?.learning?.score || 0),
            weekStart: DateTime.fromISO(item?.startDate).toFormat("dd LLL"),
            weekEnd: DateTime.fromISO(item?.endDate).toFormat("dd LLL"),
          };
        });
        setData(formattedData);
        setLoading(false);
        setTotalCount(res?.data?.data?.count);
      } catch (error) {
        errorToastMessage(error as Error);
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading, setData, setTotalCount, page, id, sortOrder, sortColumn]);

  const handleChangePage = (_: any, newPage: number) => {
    dispatch(setScorePage(newPage));
  };

  const handleSort = (order: string, column: string) => {
    if (sortColumn === column && sortOrder === order) {
      dispatch(setSortOrder(""));
      dispatch(setSortColumn(""));
    } else {
      dispatch(setSortOrder(order));
      dispatch(setSortColumn(column));
    }
  };

  const SortLabel = ({ column }: { column: string }) => {
    return (
      <>
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "asc"}
          direction="asc"
          hideSortIcon={false}
          onClick={() => handleSort("asc", column)}
        />
        <StyledSortLabel
          active={column === sortColumn && sortOrder === "desc"}
          direction="desc"
          hideSortIcon={false}
          onClick={() => handleSort("desc", column)}
        />
      </>
    );
  };

  return (
    <>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              Week
              <SortLabel column="endDate" />
            </StyledTableCell>
            <StyledTableCell>
              Total weekly score
              <SortLabel column="score" />
            </StyledTableCell>
            <StyledTableCell>Medication</StyledTableCell>
            <StyledTableCell>Food</StyledTableCell>
            <StyledTableCell>Steps</StyledTableCell>
            <StyledTableCell>Sleep</StyledTableCell>
            <StyledTableCell>Educational content</StyledTableCell>
          </TableRow>
        </TableHead>
        {!loading && data.length > 0 && (
          <>
            <TableBody>
              {data.map((item: any) => (
                <TableRow key={item.id}>
                  <StyledTableCell>
                    {item?.weekStart} - {item?.weekEnd}
                  </StyledTableCell>
                  <StyledTableCell>{item?.totalScore || 0}</StyledTableCell>
                  <StyledTableCell>{item?.medication || 0}</StyledTableCell>
                  <StyledTableCell>{item?.food || 0}</StyledTableCell>
                  <StyledTableCell>{item?.steps || 0}</StyledTableCell>
                  <StyledTableCell>{item?.sleep || 0}</StyledTableCell>
                  <StyledTableCell>{item?.learn || 0}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {totalCount > pageSize && (
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={totalCount}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                )}
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
      {!loading && data.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No data available
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};

export default ActionScores;
