import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

//prod
// const firebaseConfig = {
//   apiKey: "AIzaSyBc0qIKQpgXnV5pvXNPwyvN1t4hSWLQmVc",
//   authDomain: "lkc-cadence.firebaseapp.com",
//   projectId: "lkc-cadence",
//   storageBucket: "lkc-cadence.appspot.com",
//   messagingSenderId: "200687274954",
//   appId: "1:200687274954:web:64b66f25a11178d6cd9d33",
//   measurementId: "G-EP956XWGWY"
// }

//staging
// const firebaseConfig = {
//   apiKey: "AIzaSyCN_HmKQ0y9ifUP9HRwn5ehbEzqsvb_X64",
//   authDomain: "gcp-lkc-cadence-stg-41bc.firebaseapp.com",
//   projectId: "gcp-lkc-cadence-stg-41bc",
//   storageBucket: "gcp-lkc-cadence-stg-41bc.firebasestorage.app",
//   messagingSenderId: "196052007754",
//   appId: "1:196052007754:web:2afabdf20612e965e2dfb3",
// };

//dev
const firebaseConfig = {
  apiKey: "AIzaSyAZmL98-6kpow9anYA7OFmXfi9z0m5N7ro",
  authDomain: "cadence-7a968.firebaseapp.com",
  projectId: "cadence-7a968",
  storageBucket: "cadence-7a968.appspot.com",
  messagingSenderId: "163695402346",
  appId: "1:163695402346:web:8f6173360a0e0463971913",
  measurementId: "G-3JPEG1YC9W",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { db, app, auth };
